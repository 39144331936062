import { useState } from "react";
import { Col, FormGroup, FormInput, FormSelect, FormTextarea, Row } from "shards-react";

const FunctionInputUnit = ({ name, index, setFunctionParams }) => {
	const [functionName, setFunctionName] = useState("");
	const [functionLogic, setFunctionLogic] = useState("");
	const [parameterCount, setParameterCount] = useState(1);
	const [parameters, setParameters] = useState([""]);

	const handleParameterCountChange = (e) => {
		const count = parseInt(e.target.value, 10);
		setParameterCount(count);
		// Adjust the parameters array length
		if (count > parameters.length) {
			setParameters([...parameters, ...Array(count - parameters.length).fill("")]);
			setFunctionParams((prev) => {
				if (index > prev.length) {
					return [...prev, { params: [parameters.slice(0, count)] }];
				} else {
					return prev.map((item, idx) => {
						if (idx + 1 === index) {
							return { ...item, params: [parameters.slice(0, count)] };
						} else {
							return item;
						}
					});
				}
			});
		} else {
			setParameters(parameters.slice(0, count));
			setFunctionParams((prev) => {
				if (index > prev.length) {
					return [...prev, { params: [parameters.slice(0, count)] }];
				} else {
					return prev.map((item, idx) => {
						if (idx + 1 === index) {
							return { ...item, params: [parameters.slice(0, count)] };
						} else {
							return item;
						}
					});
				}
			});
		}
	};

	const handleParameterNameChange = (paramindex, value) => {
		const updatedParameters = [...parameters];
		updatedParameters[paramindex] = value;
		setParameters(updatedParameters);
		setFunctionParams((prev) => {
			if (index > prev.length) {
				return [...prev, { params: updatedParameters }];
			} else {
				return prev.map((item, idx) => {
					if (idx + 1 === index) {
						return { ...item, params: updatedParameters };
					} else {
						return item;
					}
				});
			}
		});
	};

	const functionLogicChange = ({ target: { value } }) => {
		setFunctionLogic(value);
		setFunctionParams((prev) => {
			if (index > prev.length) {
				return [...prev, { logic: value }];
			} else {
				return prev.map((item, idx) => {
					if (idx + 1 === index) {
						return { ...item, logic: value };
					} else {
						return item;
					}
				});
			}
		});
	};

	const functionNameChange = ({ target: { value } }) => {
		setFunctionName(value);
		setFunctionParams((prev) => {
			if (index > prev.length) {
				return [...prev, { functionName: value }];
			} else {
				return prev.map((item, idx) => {
					if (idx + 1 === index) {
						return { ...item, functionName: value };
					} else {
						return item;
					}
				});
			}
		});
	};

	return (
		<>
			{name === "game" ? (
				<FormSelect value={functionName} onChange={functionNameChange} className="mb-3">
					<option value="" disabled>
						-Select game type-
					</option>
					<option value="moves">moves</option>
					<option value="turn">turn</option>
					<option value="endIf">endif</option>
					<option value="onEnd">onEnd</option>
					<option value="other">other</option>
				</FormSelect>
			) : (
				""
			)}
			<FormTextarea
				size="lg"
				className="mb-3"
				placeholder={`Add ${name} function ${index}`}
				value={functionLogic}
				onChange={functionLogicChange}
				rows={4}
			/>
			<FormGroup>
				<label htmlFor="parameterNum">Number of parameter(s) for function {index}</label>
				<FormInput
					id="parameterNum"
					placeholder="Number of parameters (Eg. 2)"
					type="number"
					value={parameterCount}
					onChange={handleParameterCountChange}
				/>
			</FormGroup>
			<Row>
				<Col className="auto d-flex">
					{Array.from({ length: parameterCount }, (_, index) => (
						<FormInput
							key={index}
							placeholder={`Parameter ${index + 1}`}
							value={parameters[index] || ""}
							onChange={(e) => handleParameterNameChange(index, e.target.value)}
							className="mr-2"
						/>
					))}
				</Col>
			</Row>
		</>
	);
};

export default FunctionInputUnit;
