import { useState } from "react";
import { Col, FormGroup, FormInput, FormTextarea, Row } from "shards-react";

const BoardInputUnit = ({ setFunctionParams }) => {
	const [functionLogic, setFunctionLogic] = useState("");
	const [parameterCount, setParameterCount] = useState(1);
	const [parameters, setParameters] = useState([""]);

	const handleParameterCountChange = (e) => {
		const count = parseInt(e.target.value, 10);
		setParameterCount(count);
		// Adjust the parameters array length
		if (count > parameters.length) {
			setParameters([...parameters, ...Array(count - parameters.length).fill("")]);
			setFunctionParams((prev) => {
				return prev.map((item, idx) => {
					if (idx === 0) {
						return { ...item, params: [parameters.slice(0, count)] };
					} else {
						return item;
					}
				});
			});
		} else {
			setParameters(parameters.slice(0, count));
			setFunctionParams((prev) => {
				return prev.map((item, idx) => {
					if (idx === 0) {
						return { ...item, params: [parameters.slice(0, count)] };
					} else {
						return item;
					}
				});
			});
		}
	};

	const handleParameterNameChange = (paramindex, value) => {
		const updatedParameters = [...parameters];
		updatedParameters[paramindex] = value;
		setParameters(updatedParameters);
		setFunctionParams((prev) => {
			return prev.map((item, idx) => {
				if (idx === 0) {
					return { ...item, params: updatedParameters };
				} else {
					return item;
				}
			});
		});
	};

	const functionLogicChange = ({ target: { value } }) => {
		setFunctionLogic(value);
		setFunctionParams((prev) => {
			return prev.map((item, idx) => {
				if (idx === 0) {
					return { ...item, logic: value };
				} else {
					return item;
				}
			});
		});
	};

	return (
		<>
			<FormGroup>
				<label htmlFor="parameterNum">Number of parameter(s)</label>
				<FormInput
					id="parameterNum"
					placeholder="Number of parameters (Eg. 2)"
					type="number"
					value={parameterCount}
					onChange={handleParameterCountChange}
				/>
			</FormGroup>
			<Row>
				<Col className="d-flex">
					{Array.from({ length: parameterCount }, (_, index) => (
						<FormInput
							key={index}
							placeholder={`Parameter ${index + 1}`}
							value={parameters[index] || ""}
							onChange={(e) => handleParameterNameChange(index, e.target.value)}
							className="mr-2 mb-3"
						/>
					))}
				</Col>
			</Row>

			<FormTextarea
				size="lg"
				className="mb-3"
				placeholder={`Add Logic`}
				value={functionLogic}
				onChange={functionLogicChange}
				rows={4}
			/>
		</>
	);
};

export default BoardInputUnit;
