import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, FormInput, FormSelect, Row } from "shards-react";
import CustomModal from "../common/Modal";

const SidebarTags = ({ checkedRooms, selectedTags, setSelectedTags, addChannelTags }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [channelTags, setChannelTags] = useState([]);
	const [tag, setTag] = useState("");

	const handleTagChange = (tag) => {
		setSelectedTags((prevTags) =>
			prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
		);
	};

	const handleAddTag = ({ target: { value } }) => {
		if (!channelTags.includes(value)) {
			setChannelTags([...channelTags, value]);
		}
	};

	const handleEditTags = () => {
		if (checkedRooms.length > 0 && checkedRooms[0].tags) {
			setChannelTags([...checkedRooms[0].tags]);
		}
		setModalOpen(true);
	};

	const handleSave = async () => {
		if (channelTags.length > 0) {
			await addChannelTags(channelTags);
			setModalOpen(false);
			setChannelTags([]);
		}
	};

	const handleClose = () => {
		setModalOpen(false);
		setChannelTags([]);
	};

	const handleRemoveTag = (tag) => {
		let newChannelTags = channelTags.filter((ctag) => ctag !== tag);
		setChannelTags(newChannelTags);
	};

	return (
		<>
			<Card className="rounded-0">
				<CardBody>
					<div>
						<p>Tags</p>
						{checkedRooms &&
							checkedRooms.map(({ tags }, index) => (
								<div key={index}>
									{tags && tags.length > 0 ? (
										<div className="flex flex-column">
											{tags.map((tag) => (
												<div key={tag}>
													<input
														type="checkbox"
														checked={selectedTags.includes(tag)}
														onChange={() => handleTagChange(tag)}
													/>{" "}
													<span>{tag}</span>
												</div>
											))}
											<div className="flex">
												<Button
													onClick={handleEditTags}
													squared
													className="rounded-0 btn-outline-primary blue-btn-outline px-3"
												>
													EDIT TAGS
												</Button>
											</div>
										</div>
									) : (
										<p className="flex gap-2 items-center m-0">
											<span>No tags found</span>
											<Button
												onClick={() => setModalOpen(true)}
												squared
												className="rounded-0 btn-outline-primary blue-btn-outline px-3"
											>
												ADD TAGS
											</Button>
										</p>
									)}
								</div>
							))}
					</div>
				</CardBody>
			</Card>
			{modalOpen && checkedRooms.length !== 0 && (
				<CustomModal
					modalOpen={modalOpen}
					modalTitle={checkedRooms.length > 0 && checkedRooms[0].tags ? "Edit Tags" : "Add Tags"}
					affirmText={checkedRooms.length > 0 && checkedRooms[0].tags ? "Update" : "Add"}
					action={handleSave}
					closeModal={handleClose}
				>
					<div className="flex flex-column gap-4">
						<div className="flex gap-2 flex-wrap">
							{channelTags.length > 0 &&
								channelTags.map((tag) => (
									<span
										className="border border-1 p-2"
										key={tag}
										onClick={() => handleRemoveTag(tag)}
									>
										{tag}
									</span>
								))}
						</div>
						<Row className="mb-3 no-gutters d-flex">
							<FormSelect onChange={handleAddTag}>
								<option value="" disabled>
									-Select type-
								</option>
								<option value="Writing">Writing</option>
								<option value="Homework">Homework</option>
								<option value="Classroom">Classroom</option>
								<option value="Reading">Reading</option>
								<option value="Hygiene">Hygiene</option>
								<option value="Nutrition">Nutrition</option>
								<option value="FirstAid">First aid</option>
								<option value="Exercise">Exercise</option>
							</FormSelect>
						</Row>
						<div className="flex ">
							<FormInput
								size="md"
								placeholder="Type your tag here"
								value={tag?.target?.value}
								onChange={(e) => setTag(e)}
							/>
							<Button onClick={() => handleAddTag(tag)}>Add</Button>
						</div>
					</div>
				</CustomModal>
			)}
		</>
	);
};

SidebarTags.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

SidebarTags.defaultProps = {
	title: "Channels",
};

export default SidebarTags;
