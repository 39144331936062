"use client";

import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	ListGroupItem,
	Button,
	InputGroup,
	InputGroupAddon,
	FormRadio,
	FormInput,
	Row,
	Col,
	ButtonGroup,
} from "shards-react";

const SidebarChannels = ({
	title,
	channels,
	checkedRooms,
	setCheckedRooms,
	channelTag,
	setChannelTag,
	selectedTags,
	setSelectedTags,
}) => {
	let showChannels = [];

	if (channelTag === "cinema") {
		showChannels = channels.filter((channel) => channel.layout === "cinema");
	}
	if (channelTag === "school") {
		showChannels = channels.filter((channel) => channel.layout === "school");
	}
	if (channelTag === "radio") {
		showChannels = channels.filter((channel) => channel.layout === "radio");
	}
	if (channelTag === "arcade") {
		showChannels = channels.filter((channel) => channel.layout === "arcade");
	}

	const handleSelectedTagsUpdate = (channel) => {
		let channelTags = channel?.tags ?? [];
		const newSelectedTags = [];
		channelTags.forEach((tag) => {
			if (selectedTags.includes(tag)) {
				newSelectedTags.push(tag);
			}
		});
		setSelectedTags(newSelectedTags);
	};

	return (
		<>
			<Row>
				<Col>
					<ButtonGroup>
						<Button
							className={`blue-banner channel-buttons scriber-bold ${channelTag === "school" ? "active" : ""}`}
							onClick={() => setChannelTag("school")}
						>
							SCHOOL
						</Button>
						<Button
							className={`blue-banner channel-buttons scriber-bold ${channelTag === "cinema" ? "active" : ""}`}
							onClick={() => setChannelTag("cinema")}
						>
							CINEMA
						</Button>
						<Button
							className={`blue-banner channel-buttons scriber-bold ${channelTag === "radio" ? "active" : ""}`}
							onClick={() => setChannelTag("radio")}
						>
							RADIO
						</Button>
						<Button
							className={`blue-banner channel-buttons scriber-bold ${channelTag === "arcade" ? "active" : ""}`}
							onClick={() => setChannelTag("arcade")}
						>
							ARCADE
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card small className="rounded-0 blue-border">
						<CardHeader className="border-bottom">
							<h6 className="m-0">{title}</h6>
						</CardHeader>
						<CardBody className="p-0">
							<ListGroup flush style={{ height: "32rem" }}>
								<ListGroupItem className="px-3 pb-2 overflow-auto">
									{channels &&
										showChannels.map((channel, index) => (
											<FormRadio
												key={index}
												className="mb-1"
												checked={checkedRooms.some((room) => room.key === channel.key)}
												name={channel.title}
												onChange={() => {
													setCheckedRooms((prev) => {
														if (prev.some((room) => room.key === channel.key)) {
															// If the channel is already selected, uncheck it
															return [];
														} else {
															// Otherwise, set it as the only selected channel
															return [channel];
														}
													});
													handleSelectedTagsUpdate(channel);
												}}
											>
												{channel.key}
											</FormRadio>
										))}

									{/* <div>Public Channels</div>
									{publicChannels &&
										publicChannels.map((publicChannel, index) => (
											<FormCheckbox
												key={index}
												className="mb-1"
												checked={checkedPublicChannel.some(
													(room) => room.channelId === publicChannel.channelId
												)} // corrected variable name and comparison
												name={publicChannel.channelName}
												onChange={() => handleCheckPublic(publicChannel)}
											>
												{publicChannel.channelName}
											</FormCheckbox>
										))} */}
								</ListGroupItem>

								<ListGroupItem className="d-flex px-3">
									<InputGroup className="ml-auto">
										<FormInput placeholder="New channel" />
										<InputGroupAddon type="append">
											<Button theme="white" className="px-2">
												<i className="material-icons">add</i>
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</ListGroupItem>
							</ListGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

SidebarChannels.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

SidebarChannels.defaultProps = {
	title: "Channels",
};

export default SidebarChannels;
